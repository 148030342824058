import React from 'react'
import { HiOutlineMenuAlt2, HiOutlineMenu } from 'react-icons/hi'

const NavToggle = ({toggled, className}) => {
	return (
		<div className={className}>
			{toggled ? <HiOutlineMenu className='text-white'/> : <HiOutlineMenuAlt2 className='text-white'/>}
		</div>
	)
}

export default NavToggle