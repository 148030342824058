import React, { useEffect, useState } from "react";
import { toast, Notification } from "components/ui";
import {
  requestPermission,
  onMessageListener,
} from "services/firebaseService.js";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TYPE, USER_TYPE } from "constants/roles.constant";
import { APP_PREFIX_PATH } from "constants/route.constant";
// import useSound from "use-sound";
import store from "store";

const FirebaseNotification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [tripData, setTripData] = useState();
  const navigate = useNavigate();
  const userType = store?.getState()?.auth?.user.userType;
  const redirect = userType === USER_TYPE.TRANSPORTER_MANAGER

  // const [playOn] = useSound("toast_sound.mp3");
  const navigationHandler = (type, id) => {
    switch (type) {
      case NOTIFICATION_TYPE.TRIP_UPDATES:
        if(redirect)
        navigate(`${APP_PREFIX_PATH}/track-path/${id}`);
        break;
      case NOTIFICATION_TYPE.TRIP_ISSUE_HALT:
      case NOTIFICATION_TYPE.TRIP_ASSIGNED:
        navigate(`${APP_PREFIX_PATH}/trips`);
        break;
      case NOTIFICATION_TYPE.DRIVER_LICENSE_END:
        navigate(`${APP_PREFIX_PATH}/user-management/drivers/edit/${id}`);
        break;
      case NOTIFICATION_TYPE.TRANSPORTER_AGGREMENT_END:
        navigate(`${APP_PREFIX_PATH}/user-management/transporters/edit/${id}`);
        break;
      default:
        return false;
    }
  };
  const playSound = () => {
    const audio = new Audio("/toast_sound.mp3");
    audio.play();
  };

  const notify = () => {
    return toast.push(
      <Notification
        onClick={() =>
          navigationHandler(parseInt(tripData?.type), tripData?.tripId)
        }
        title={`${notification?.title} - ${notification?.body}`}
        type="success"
        customIcon={<img src={tripData?.icon} className="h-20 w-20" />}
      >
        <p>{tripData?.description}</p>
      </Notification>,
      {
        placement: "top-end",
      }
    );
  };

  useEffect(() => {
    if (notification?.title) {
      playSound();
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      console.log("foreground notification", payload);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      setTripData(payload?.data);
    })
    .catch((err) => {
      console.log("Error occured during getting message=>", err);
    });

  requestPermission();
  return <></>;
};

export default FirebaseNotification;
