const appConfig = {
  apiBaseUrl: "https://vahanapi.smlisuzu.com/",
  apiPrefix: "/api",
  authenticatedEntryPath: "/app/dashboard",
  imageBaseUrl: "https://vahansuraksha.s3.ap-south-1.amazonaws.com/",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: true,
};

export default appConfig;
