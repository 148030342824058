import store from "store";
import { USER_NAME } from "constants/roles.constant";

export const generateDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = `userID_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  }
  localStorage.setItem("device_id", deviceId);
  return deviceId;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hasPermission = (userAuthority) => {
  const { authority } = store?.getState()?.auth?.user;
  const roleMatched = userAuthority.some((role) => authority.includes(role));

  return roleMatched;
};

export const getAPIAccToUserType = (api) => {
  const userType = store?.getState()?.auth?.user.userType;

  switch (userType) {
    case 4:
    case 2:
    case 3:
    case 1:
      return `/admin${api}`;
    case 5:
      return `/transporterManager${api}`;
    case 6:
      return `/transporters${api}`;
    case 8:
      return `/destinations${api}`;
    case 9:
      return `/quality${api}`;
    default:
      return "handle api for same==>", userType;
  }
};

export const getUserName = (api) => {
  const { userType } = store?.getState()?.auth?.user;
  const userName = USER_NAME.find((name) => name.value === userType)?.label;
  return userName ? userName : "";
};

export const getExtentionFromImageName = (imgName) => {
  const extention = imgName.split(".").pop();
  return extention;
};
